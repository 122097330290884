import EnglishTranslation from "~/lang/en.yaml";
import GermanTranslation from "~/lang/de.yaml";

export default defineI18nConfig(() => {
    return {
        legacy: false,
        locale: 'de',
        globalInjection: true,
        messages: {
            en: EnglishTranslation,
            de: GermanTranslation,
        },
    }
})
